<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="refRichText"
    class="atom-richtext"
    v-html="html"
  />
</template>

<script setup>
defineProps({
    html: {
        type: String,
        required: true,
        default: '',
        validator: (value) => typeof value === 'string',
    },
});

const refRichText = ref(null);

onMounted(() => {
    const links = refRichText.value.querySelectorAll('a');

    links.forEach((link) => {
        if (link.getAttribute('linktype') === 'url') {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        }

        if (link.getAttribute('href').includes('mailto:') || link.getAttribute('href').includes('tel:')) {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        }
    });
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.atom-richtext {
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    word-break: break-word;

    .block-calculation-details & {
        margin-top: 17px;
    }

    .block-download-hub & {
        font-size: var(--f-size--description);
        line-height: var(--l-height--description);
    }

    .block-footer & {
        font-family: var(--f-family--thin);
    }

    .storyblok-info-box__wrapper & {
        h3 {
            font-family: var(--f-family--thin);
            font-size: var(--f-size--sublinks-nav);
            line-height: var(--l-height--sublinks-nav);
        }
    }

    .block-filter-activities__noArticles & {
        font-family: var(--f-family--thin);
    }

    h1 {
        margin-bottom: 1em;
    }

    h2 {
        margin-bottom: 15px;
        font-size: var(--f-size--programm-title);
        line-height: var(--l-height--programm-title);
        span {
            color: v-bind(color1);
        }
    }

    h3,
    h4 {
        margin-top: 2em;
        margin-bottom: 0.5em;

        .block-footer & {
            @include fluid(font-size,  20px, 22px);
            @include fluid(line-height,  26px, 32px);

            margin-top: 0;
            margin-bottom: 18px;
            color: v-bind(color1);
            @include mobile {
                margin-bottom: 10px;
                font-family: var(--f-family--regular);
            }
        }
    }

    h5,
    h6 {
        margin-bottom: 0.5em;
        color: var(--c-black);
    }

    p {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            color:v-bind(color4);
            .block-footer & {
                color: v-bind(color4);
            }
        }

        .block-footer & {
            color: v-bind(color1);
        }

        .block-item-info-programm__item-text & {
            margin-bottom: 0;
        }
        .block-item-key-figures-international & {
            margin-bottom: 0;
        }
    }

    h3:first-of-type,
    h4:first-of-type,
    h5:first-of-type,
    h6:first-of-type {
        .app-footer & {
            margin-top: 0;
            margin-bottom: 10px;

            @include mobile {
                margin-bottom: 10px;
            }
        }
    }

    hr {
        border: 1px solid currentColor;
        margin-bottom: 2em;
    }

    img {
        max-width: 100%;
        margin-bottom: 2em;
    }

    figure {
        margin-bottom: 2em;

        img {
            margin-bottom: 0;
        }
    }

    strong, b {
        font-family: var(--f-family--bold);
    }

    strike {
        text-decoration: line-through;
    }

    u {
        text-decoration: underline;
    }

    i {
        font-style: italic;
    }

    em {
        font-style: italic;
    }

    q {
        font-style: italic;
    }

    ul {

        padding-left: 23px;
        list-style: disc;

        li {
            line-height: 130%;
        }
    }

    table {
        width: 100%;
        margin-bottom: 2em;
        border-collapse: collapse;
        border-spacing: 0;

        caption {
            font-family: var(--f-family--bold);
        }

        tbody {
            border-top: 1px solid var(--c-black);
        }

        tr {
            border-bottom: 1px solid var(--c-black);
        }

        th {
            font-family: var(--f-family--bold);
        }

        caption,
        th,
        td {
            padding: 1em 0;
            vertical-align: top;
        }

        th,
        td {
            &:not(:last-child) {
                padding-right: 1em;
            }
        }
    }

    .atom-image + .block-richtext & {
        h3,
        h4 {
            margin-top: 0;
            margin-bottom: 30px;
        }
    }

    &.block-flyout-contact__content-main-contact-link-text {
        p {
            color: var(--c-white);
        }
    }

    .atom-input-radio-calc__hint-box &,
    .block-calculation-details & {
        p {
            color: var(--c-white);
        }
    }

    .storyblok-info-box & {
        p {
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>
